import { ActionInterface } from '@/actions';
import { GameStep } from '@/config/game/steps';
import {
  additionalGamesReducer,
  AdditionalGamesState,
} from '@/reducers/additionalGames';
import { couponGroupReducer, CouponGroupState } from '@/reducers/couponGroup';
import {
  currentCouponIdReducer,
  CurrentCouponIdState,
} from '@/reducers/currentCouponId';
import { currentStepReducer, CurrentStepState } from '@/reducers/currentStep';
import { drawDaysReducer, DrawDaysState } from '@/reducers/drawDays';
import { drawNumberReducer, DrawNumberState } from '@/reducers/drawNumber';
import { drawRateReducer, DrawRateState } from '@/reducers/drawRate';
import { playNumberReducer, PlayNumberState } from '@/reducers/playNumber';
import {
  weeklySubscriptionReducer,
  WeeklySubscriptionState,
} from '@/reducers/weeklySubscription';
import { weeksNumberReducer, WeeksNumberState } from '@/reducers/weeksNumber';

export interface GameState {
  couponGroup: CouponGroupState;
  selectedPlayNumber: PlayNumberState;
  selectedDrawDays: DrawDaysState;
  selectedWeeksNumber: WeeksNumberState;
  selectedDrawNumber: DrawNumberState;
  selectedWeeklySubscription: WeeklySubscriptionState;
  selectedDrawRate: DrawRateState;
  selectedAdditionalGames: AdditionalGamesState;
  currentStep: CurrentStepState;
  currentCouponId: CurrentCouponIdState;
}

export const gameReducer = (
  state: GameState = {
    couponGroup: {},
    selectedPlayNumber: [],
    selectedDrawDays: [],
    selectedWeeksNumber: 0,
    selectedDrawNumber: 1,
    selectedDrawRate: 1,
    selectedWeeklySubscription: false,
    selectedAdditionalGames: [],
    currentStep: GameStep.Coupons,
    currentCouponId: 0,
  },
  action: ActionInterface
) => {
  return {
    ...state,
    couponGroup: couponGroupReducer(state.couponGroup, action),
    selectedPlayNumber: playNumberReducer(state.selectedPlayNumber, action),
    selectedDrawDays: drawDaysReducer(state.selectedDrawDays, action),
    selectedWeeksNumber: weeksNumberReducer(state.selectedWeeksNumber, action),
    selectedWeeklySubscription: weeklySubscriptionReducer(
      state.selectedWeeklySubscription,
      action
    ),
    selectedDrawNumber: drawNumberReducer(state.selectedDrawNumber, action),
    selectedDrawRate: drawRateReducer(state.selectedDrawRate, action),
    selectedAdditionalGames: additionalGamesReducer(
      state.selectedAdditionalGames,
      action
    ),
    currentStep: currentStepReducer(state.currentStep, action),
    currentCouponId: currentCouponIdReducer(state.currentCouponId, action),
  };
};
