import Button from '@/components/Button';
import { Icon, IconVariant } from '@/components/Icon';
import React from 'react';

interface BackButtonState {
  canBack: boolean;
}

export interface BackButtonProps {
  onClick?: () => void;
}

class BackButton extends React.Component<BackButtonProps, BackButtonState> {
  constructor(props) {
    super(props);
    this.state = { canBack: false };
  }

  componentDidMount(): void {
    let canBack = false;
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const ref = document.referrer;
      const url = window.location.href;
      canBack = !!ref && ref !== url;
    }
    this.setState({ canBack });
  }

  private get label(): string {
    return this.state.canBack || this.props.onClick ? 'Zurück' : 'Start';
  }

  private get onClick(): Function {
    return (
      this.props.onClick ||
      (() =>
        this.state.canBack
          ? window.history.back()
          : (window.location.href = '/'))
    );
  }

  render(): JSX.Element {
    return (
      <Button variant={Button.Variant.Standard} onClick={() => this.onClick()}>
        <Icon variant={IconVariant.ArrowLeft} />
        <span className="h-hide-xxs--down">{this.label}</span>
      </Button>
    );
  }
}

export default BackButton;
