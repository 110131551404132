import { ActionInterface, ActionType } from '@/actions';

export type WeeklySubscriptionState = boolean;

export const weeklySubscriptionReducer = (
  state: WeeklySubscriptionState,
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.WEEKLY_SUBSCRIPTION_TOGGLE:
      return !state;

    default:
      return state;
  }
};
