/* eslint-disable no-control-regex */
export interface Validation {
  message: string;
  regex: RegExp;
  maxLength?: number;
}

export const VALIDATIONS: { [key: string]: Validation } = {
  email: {
    message: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
    regex:
      /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/,
  },
  plz: {
    message: 'Bitte überprüfen Sie Ihre Angaben.',
    regex: /^[\d]{5}$/,
    maxLength: 5,
  },
  required: {
    message: 'Bitte füllen Sie dieses Feld aus.',
    regex: /^./,
  },
  password: {
    message: 'Bitte füllen Sie dieses Feld aus.',
    regex: /^./,
  },
};
