import {
  ActionGameFormFunction,
  ActionGameFormVariantFunction,
  SubsidiaryObject,
} from '@/actions/gameForm/interfaces';
import Button from '@/components/Button';
import {
  FormControlData,
  FormControlText,
  FormControlTextType,
} from '@/components/FormControlText';
import FriendlyCaptcha from '@/components/FriendlyCaptcha';
import { GameFormVariant } from '@/components/GameFormVariant';
import { Icon, IconVariant } from '@/components/Icon';
import Modal from '@/components/Modal';
import ModalRegisterInfo from '@/components/ModalRegisterInfo';
import { VALIDATIONS } from '@/config/forms/validations';
import pushDatalayer, { DataLayerEvent } from '@/utilities/pushDatalayer';
import setFocusIfElementExists from '@/utilities/setFocusIfElementExists';
import { subsidiaryConfig } from '@/utilities/subsidiaries';
import { usePathname } from 'next/navigation';
import React from 'react';

let isInitialized = false;

const GameFormRegister = ({
  email,
  plz,
  onSelect,
  subsidiary,
  isLoading,
  subsidiaryList,
  errorCode,
  disabled,
  isCaptchaEnabled,
  captchaRef,
  callbackDone,
  onChangeSubsidiary,
  onChangeFormVariant,
  onChange,
}: {
  email: FormControlData;
  plz: FormControlData;
  onSelect: any;
  isLoading: boolean;
  errorCode: number;
  subsidiary: string;
  subsidiaryList: SubsidiaryObject[] | null;
  disabled: boolean;
  isCaptchaEnabled: boolean;
  captchaRef: React.RefObject<FriendlyCaptcha>;
  callbackDone: (solution: string) => any;
  onChangeFormVariant: ActionGameFormVariantFunction;
  onChangeSubsidiary: ActionGameFormFunction;
  onChange: (id: string, value: string, isTouched?: boolean) => void;
}) => {
  const pathname = usePathname();
  const submitButtonText =
    pathname === '/login' || pathname === '/registrierung'
      ? 'Registrieren'
      : 'Registrieren & Spiel abgeben';

  React.useEffect(() => {
    if (!isInitialized) {
      setTimeout(() => {
        setFocusIfElementExists('#email');
      }, 100);
      isInitialized = true;
    }
  });

  return (
    <div className="GameFormRegister" tabIndex={0}>
      <div className="GameForm__container">
        <h1>Registrierung</h1>
        <h2 className="Step__header h-flex--justify-start">
          <span>
            Registrieren Sie sich jetzt direkt bei Ihrer
            Landeslotteriegesellschaft{' '}
            <Button
              ariaLabel="Weitere Informationen"
              id="button-register-info"
              onClick={() => Modal.open('modal-register-info')}
            >
              <Icon variant={IconVariant.Info} />
            </Button>
          </span>
        </h2>
        <div className="GameForm__required-info">
          <span className="GameForm__required">*</span> kennzeichnet
          Pflichtfelder
        </div>
      </div>

      <div className="GameForm__container">
        <div className="GameForm__group">
          <label className="GameForm__label" htmlFor="email">
            E-Mail Adresse<span className="GameForm__required">*</span>
          </label>

          <FormControlText
            id="email"
            name="email"
            type={FormControlTextType.Email}
            value={email.value}
            onChange={onChange}
            required={true}
            errorMessage={VALIDATIONS.email.message}
            isValid={email.isValid}
            isTouched={email.isTouched}
            autoComplete="email"
            errorId={email.isTouched && !email.isValid ? 'email-error' : ''}
          />
        </div>

        <div className="GameForm__group">
          <label className="GameForm__label" htmlFor="plz">
            Ihr Bundesland<span className="GameForm__required">*</span>
          </label>

          <select
            defaultValue=""
            id="plz"
            name="plz"
            required={true}
            className="GameForm__select"
            onChange={(event) => {
              onChange(event.target.id, event.target.value, true);
            }}
          >
            <option value="" disabled>
              Bitte wählen Sie...
            </option>
            {subsidiaryConfig
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map((subsidiary) => (
                <option key={subsidiary.plz} value={subsidiary.plz}>
                  {subsidiary.displayName}
                </option>
              ))}
          </select>
        </div>

        {errorCode && (
          <div className="FormControlText__error-message h-spacer-medium-bottom">
            {errorCode === 404 || errorCode === 400
              ? 'Ein Fehler ist aufgetreten. Bitte überprüfen Sie die Angaben und versuchen es erneut.'
              : errorCode === 999
              ? 'Die Sicherheitsprüfung ist fehlgeschlagen - bitte unten bei der Anti-Roboter-Verifizierung nochmal "Hier klicken" aktivieren.'
              : errorCode === 998
              ? 'Es ist ein Verbindungsproblem aufgetreten. Bitte versuchen Sie es auf der Webseite der Lotteriegesellschaft Ihres Bundeslandes oder morgen erneut auf LOTTO.de.'
              : 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es auf der Webseite der Lotteriegesellschaft Ihres Bundeslandes oder morgen erneut auf LOTTO.de.'}
          </div>
        )}

        <Button
          type="submit"
          id="register-button"
          variant={Button.Variant.Loading}
          isLoading={isLoading}
          fullWidth={true}
          disabled={disabled || !email.isValid || !plz.isValid}
          onClick={() => {
            pushDatalayer(
              `click_element_name`,
              `Button - Registrieren & Spiel abgeben`,
              DataLayerEvent.UserClick
            );
          }}
        >
          {submitButtonText}
        </Button>

        <div className="h-separator">oder</div>
        <Button
          variant={Button.Variant.Secondary}
          disabled={isLoading}
          fullWidth={true}
          onClick={() => {
            pushDatalayer(
              `click_element_name`,
              `Button - Ich bin bereits Kunde`,
              DataLayerEvent.UserClick
            );
            onChangeFormVariant(GameFormVariant.Login);
          }}
        >
          Ich bin bereits Kunde
        </Button>

        {isCaptchaEnabled && (
          <div className="GameForm__group">
            <label className="GameForm__label">Sicherheitsprüfung</label>
            <FriendlyCaptcha
              ref={captchaRef}
              callbackDone={callbackDone}
            ></FriendlyCaptcha>
          </div>
        )}
      </div>
      <ModalRegisterInfo id="modal-register-info" />
    </div>
  );
};

export default GameFormRegister;
