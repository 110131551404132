import PersistedStateWithLS from '@/store/PersistedStateWithLS';
import { IPersistedState } from '@/store/interfaces';

export default class PersistedState {
  private _storageKey: string = 'game';
  private _persist: IPersistedState;

  constructor() {
    this._persist = new PersistedStateWithLS();
  }

  private get storageKey(): string {
    const refer =
      (document.location['ancestorOrigins'] &&
        document.location['ancestorOrigins'][0]) ||
      document.referrer;

    return refer || this._storageKey;
  }

  private get persist(): IPersistedState {
    return this._persist;
  }

  public async loadState() {
    return this.persist.loadState(this.storageKey);
  }

  public async saveState(state) {
    return this.persist.saveState(this.storageKey, state);
  }

  public async deleteState() {
    return this.persist.deleteState(this.storageKey);
  }
}
