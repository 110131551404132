import LinkWrapper from '@/components/LinkWrapper';
import Modal, {
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@/components/Modal';
import React from 'react';

const ModalLoginInfo: React.FC<React.PropsWithChildren<ModalProps>> = ({
  id,
}) => {
  return (
    <Modal id={id}>
      <ModalHeader>
        <span>Anmeldung bei Ihrer Landeslotteriegesellschaft</span>
      </ModalHeader>
      <ModalContent>
        <p>
          E-Mail-Adresse und Passwort sind die Anmeldedaten, die Sie bei der
          Lotto&shy;gesellschaft Ihres Bundes&shy;landes bei der
          Erst&shy;registrierung angegeben haben. Falls Sie noch nicht
          registriert sind, können Sie sich{' '}
          <LinkWrapper href="/registrierung">hier registrieren</LinkWrapper>.
        </p>
      </ModalContent>
    </Modal>
  );
};

export default ModalLoginInfo;
