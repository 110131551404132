import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface StepProps {
  [key: string]: any;
  variant: TransitionVariant;
  timeout?: number;
  nodeRef?: React.Ref<any>;
}

export enum TransitionVariant {
  Fade = 'fade',
  SlideLeft = 'slide-left',
  SlideRight = 'slide-right',
  Zoom = 'zoom',
  Toggle = 'toggle',
}

class StepTransition extends React.Component<StepProps> {
  private timeoutDefault = 300;

  constructor(props) {
    super(props);
    if (props.variant === TransitionVariant.Toggle) {
      this.timeoutDefault = 0;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const props = this.props;
    return (
      <CSSTransition
        timeout={props.timeout || this.timeoutDefault}
        classNames={props.variant}
        nodeRef={props.nodeRef}
        {...props}
      >
        {props.children}
      </CSSTransition>
    );
  }
}

export default StepTransition;
