import { ActionInterface, ActionType } from '@/actions';

export type DrawRateState = number;

export const drawRateReducer = (
  state: DrawRateState,
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.DRAW_RATE_SET:
      return action.drawRate;

    default:
      return state;
  }
};
