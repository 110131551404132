
export default class MarketingId {
  private restUrl = process.env.SPIELSCHEIN_API_REST_URL + '/marketing-id';

  private campaign: string;
  private network: string;
  private partner: string;
  private advertisingMaterial: string;
  private additionalInfo: string;

  public constructor(campaign: string, network: string, partner: string, advertisingMaterial: string, additionalInfo: string) {
    this.campaign = campaign;
    this.network = network;
    this.partner = partner;
    this.advertisingMaterial = advertisingMaterial;
    this.additionalInfo = additionalInfo;
  }

  get requestEntity(): Object {
    return {
      kampagne: this.campaign,
      netzwerk: this.network,
      partner: this.partner,
      werbemittel: this.advertisingMaterial,
      zusatzinfos: this.additionalInfo
    }
  }

  private async marketingApiRequest(url: string, body: any) {
    const apiKeyName = process.env.SPIELSCHEIN_API_KEY_NAME;
    const headers = {
      'Content-Type': 'application/json',
    };
    if (apiKeyName) {
      headers[apiKeyName] = process.env.SPIELSCHEIN_API_KEY_VALUE;
    }
    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      return response;
    } else {
      console.error(response.statusText);
      return undefined;
    }
  }
  
  private async fetchMarketingId() {
    try {
      let response = await this.requestMarketingId();
      if (response?.status === 201) {
        // request contents from newly created marketing id
        response = await this.requestMarketingId();
      }

      if (response?.status === 200) {
        const answer = await response.text();
        if (answer === null || response.status >= 400) {
          return 1;
        }
        return Number.parseInt(answer);
      }
    } catch (error) {
      console.error(error);
    }
    return 1;
  }
  private async requestMarketingId() {
    return await this.marketingApiRequest(this.restUrl, this.requestEntity);
  }

  public async getMarketingId(): Promise<number> {
    const marketingParams = this.campaign ||
    this.network ||
    this.partner ||
    this.advertisingMaterial ||
    this.additionalInfo;
    return marketingParams ? await this.fetchMarketingId() : 1;
  }
}