import { resetGameStorage, setState } from '@/actions';
import {
  setGameFormControl,
  setGameFormError,
  setGameFormLoading,
  setGameFormSubsidiary,
  setGameFormSubsidiaryList,
  setGameFormVariant,
} from '@/actions/gameForm/gameForm';
import GameForm from '@/components/GameForm';
import { AppState } from '@/reducers';
import { connect } from 'react-redux';

const mapStateToProps = (state: AppState) => ({
  ...state.gameForm,
  gameState: { ...state.games[state.currentGameId] },
  currentGameId: state.currentGameId,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeFormControl: (key, value, isTouched?, isValid?) =>
    dispatch(setGameFormControl(key, value, isTouched, isValid)),
  onChangeFormVariant: (value) => dispatch(setGameFormVariant(value)),
  onChangeSubsidiary: (value) => dispatch(setGameFormSubsidiary(value)),
  onChangeSubsidiaryList: (value) => dispatch(setGameFormSubsidiaryList(value)),
  onChangeLoading: (value) => dispatch(setGameFormLoading(value)),
  onChangeError: (value) => dispatch(setGameFormError(value)),
  onResetGameStorage: (value) => dispatch(resetGameStorage(value)),
  onStateUpdate: (data) => dispatch(setState(data as AppState)),
});

const GameFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GameForm);
export default GameFormContainer;
