import { ActionInterface, ActionType } from '@/actions';
import { GameConfig, allGames } from '@/config/game';
import getInitialDrawRate from '@/helpers/getInitialDrawRate';
import { GameState, gameReducer } from '@/reducers/game';
import createArrayFromNumber from '@/utilities/createArrayFromNumber';

export interface GamesState {
  [game: string]: GameState;
}

export const initialGameState = (game: GameConfig) => ({
  couponGroup: game.couponGroup
    ? createArrayFromNumber(game.couponGroup.amountCoupons).reduce(
        (coupons, i) => {
          coupons[i] =
            game.couponGroup &&
            game.couponGroup.numberGroups.reduce((groups, _, j) => {
              groups[j] = [];
              return groups;
            }, {});
          return coupons;
        },
        {}
      )
    : {},
  selectedPlayNumber: [],
  selectedDrawDays: (game.drawDays && game.drawDays.initial) || [],
  selectedWeeksNumber: 1,
  selectedWeeklySubscription: false,
  selectedDrawNumber: 1,
  selectedDrawRate: getInitialDrawRate(game.drawRate),
  selectedAdditionalGames: [],
  currentStep: game.steps && game.steps[0],
  currentCouponId: 0,
});

export const initialGamesState = allGames.reduce(
  (state: GamesState, game: GameConfig): GamesState => {
    state[game.id] = initialGameState(game);
    return state;
  },
  {}
);

export const gamesReducer = (
  state: GamesState = initialGamesState,
  action: ActionInterface
) => {
  if (action.type === ActionType.GAME_RESET) {
    const currentGameConfig = allGames.find(
      (game) => game.id.toString() === action.game
    );
    return currentGameConfig
      ? {
          ...state,
          [action.game]: {
            ...initialGameState(currentGameConfig),
            currentStep: state[action.game].currentStep,
            selectedPlayNumber: state[action.game].selectedPlayNumber,
          },
        }
      : state;
  }

  return action.type !== ActionType.INIT && action.game
    ? {
        ...state,
        [action.game]: gameReducer(state[action.game], action),
      }
    : state;
};
