import { ActionInterface, ActionType } from '@/actions';

export interface DrawDaysState extends Array<string> {}

export const drawDaysReducer = (
  state: DrawDaysState = [],
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.DRAW_DAYS_SET:
      return action.days;

    default:
      return state;
  }
};
