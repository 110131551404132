import { GameFormVariant } from '@/components/GameFormVariant';
import { GameStep } from '@/config/game/steps';
import type { AppState } from '@/reducers';
import { GameState } from '@/reducers/game';

const isNumber = (data: unknown) => {
  return typeof data === 'number';
};

const isArray = (data: unknown): data is Array<unknown> => {
  if (!data) {
    return false;
  }
  return Array.isArray(data);
};

const isString = (data: unknown) => {
  return typeof data === 'string';
};

const isObject = (data: unknown) => {
  return data && typeof data === 'object';
};

const isBoolean = (data: unknown) => {
  return typeof data === 'boolean';
};

const isValidCurrentGameId = (data: unknown) => {
  // TODO typescript type in reducers/index.ts seems to be wrong
  return isString(data) || isNumber(data);
};

const isValidCouponNumberGroup = (data: unknown) => {
  if (!isArray(data)) {
    return false;
  }
  return data.every(isNumber);
};

const isValidCoupon = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  // @ts-ignore
  return Object.values(data).every(isValidCouponNumberGroup);
};

const isValidCouponGroup = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  // @ts-ignore
  return Object.values(data).every(isValidCoupon);
};

const isValidSelectedPlayNumber = (data: unknown) => {
  if (!isArray(data)) {
    return false;
  }
  return data.every(isNumber);
};

const isValidSelectedDrawDays = (data: unknown) => {
  if (!isArray(data)) {
    return false;
  }
  return data.every(isString);
};

const isValidSelectedWeeksNumber = (data: unknown) => {
  return isNumber(data);
};

const isValidSelectedDrawNumber = (data: unknown) => {
  return isNumber(data);
};

const isValidSelectedWeeklySubscription = (data: unknown) => {
  return isBoolean(data);
};

const isValidSelectedDrawRate = (data: unknown) => {
  return isNumber(data);
};

const isValidSelectedAdditionalGames = (data: unknown) => {
  if (!isArray(data)) {
    return false;
  }
  return data.every(isNumber);
};

const isValidCurrentStep = (data: unknown) => {
  if (data === undefined) {
    // TODO typescript type for current step seems to be wrong
    return true;
  }
  if (!isString(data)) {
    return false;
  }
  return (
    data === GameStep.Coupons ||
    data === GameStep.CouponsB ||
    data === GameStep.Details ||
    data === GameStep.Summary ||
    data === GameStep.Submit
  );
};

const isValidGame = (data: unknown): data is GameState => {
  if (!data || typeof data !== 'object') {
    return false;
  }
  // @ts-ignore
  return (
    // @ts-ignore
    isValidCouponGroup(data.couponGroup) &&
    // @ts-ignore
    isValidSelectedPlayNumber(data.selectedPlayNumber) &&
    // @ts-ignore
    isValidSelectedDrawDays(data.selectedDrawDays) &&
    // @ts-ignore
    isValidSelectedWeeksNumber(data.selectedWeeksNumber) &&
    // @ts-ignore
    isValidSelectedDrawNumber(data.selectedDrawNumber) &&
    // @ts-ignore
    isValidSelectedWeeklySubscription(data.selectedWeeklySubscription) &&
    // @ts-ignore
    isValidSelectedDrawRate(data.selectedDrawRate) &&
    // @ts-ignore
    isValidSelectedAdditionalGames(data.selectedAdditionalGames) &&
    // @ts-ignore
    isValidCurrentStep(data.currentStep) &&
    // @ts-ignore
    isValidCurrentCouponId(data.currentCouponId)
  );
};

const isValidCurrentCouponId = (data: unknown) => {
  // TODO typescript type for current coupon id seems to be wrong
  return isNumber(data) || data === undefined;
};

const isValidGames = (data: unknown) => {
  if (!data || typeof data !== 'object') {
    return false;
  }
  return Object.values(data).every(isValidGame);
};

const isValidEmailValue = (data: unknown) => {
  return isString(data);
};

const isValidEmailIsValid = (data: unknown) => {
  return isBoolean(data);
};

const isValidEmailIsTouched = (data: unknown) => {
  return isBoolean(data);
};

const isValidEmail = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  return (
    // @ts-ignore
    isValidEmailValue(data.value) &&
    // @ts-ignore
    isValidEmailIsValid(data.isValid) &&
    // @ts-ignore
    isValidEmailIsTouched(data.isTouched)
  );
};

const isValidPasswordValue = (data: undefined) => {
  return isString(data);
};

const isValidPasswordIsValid = (data: unknown) => {
  return isBoolean(data);
};

const isValidPasswordIsTouched = (data: unknown) => {
  return isBoolean(data);
};

const isValidPassword = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  return (
    // @ts-ignore
    isValidPasswordValue(data.value) &&
    // @ts-ignore
    isValidPasswordIsValid(data.isValid) &&
    // @ts-ignore
    isValidPasswordIsTouched(data.isTouched)
  );
};

const isValidPlzValue = (data: undefined) => {
  return isString(data);
};

const isValidPlzIsValid = (data: unknown) => {
  return isBoolean(data);
};

const isValidPlzIsTouched = (data: unknown) => {
  return isBoolean(data);
};

const isValidPlz = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  return (
    // @ts-ignore
    isValidPlzValue(data.value) &&
    // @ts-ignore
    isValidPlzIsValid(data.isValid) &&
    // @ts-ignore
    isValidPlzIsTouched(data.isTouched)
  );
};

const isValidSubsidiary = (data: unknown) => {
  return isString(data);
};

const isValidSubsidiaryObject = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  // @ts-ignore
  return isString(data.displayName) && isString(data.key);
};

const isValidSubsidiaryList = (data: unknown) => {
  if (!isArray(data)) {
    return false;
  }
  return data.every(isValidSubsidiaryObject);
};

const isValidVariant = (data: unknown) => {
  if (!isString(data)) {
    return false;
  }
  return data === GameFormVariant.Login || data === GameFormVariant.Register;
};

const isValidIsLoading = (data: unknown) => {
  return isBoolean(data);
};

const isValidErrorCode = (data: unknown) => {
  return data === null || isNumber(data);
};

const isValidGameForm = (data: unknown) => {
  if (data === undefined) {
    return true;
  }
  if (!isObject(data)) {
    return false;
  }

  return (
    // @ts-ignore
    isValidEmail(data.email) &&
    // @ts-ignore
    isValidPassword(data.password) &&
    // @ts-ignore
    isValidPlz(data.plz) &&
    // @ts-ignore
    isValidSubsidiary(data.subsidiary) &&
    // @ts-ignore
    isValidSubsidiaryList(data.subsidiaryList) &&
    // @ts-ignore
    isValidVariant(data.variant) &&
    // @ts-ignore
    isValidIsLoading(data.isLoading) &&
    // @ts-ignore
    isValidErrorCode(data.errorCode)
  );
};

const isValidAppState = (data: unknown) => {
  if (!isObject(data)) {
    return false;
  }
  return (
    // @ts-ignore
    isValidCurrentGameId(data.currentGameId) &&
    // @ts-ignore
    isValidGames(data.games) &&
    // @ts-ignore
    isValidGameForm(data.gameForm)
  );
};

export const restoreState = (data: unknown): AppState | undefined => {
  if (!data) {
    return undefined;
  }
  if (!isValidAppState(data)) {
    console.info('invalid app state detected, check local storage for details');
    return undefined;
  }
  return data as AppState;
};
