import { ActionInterface, ActionType } from '@/actions';

export type CurrentCouponIdState = number;

export const currentCouponIdReducer = (
  state: CurrentCouponIdState,
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.CURRENT_COUPON_ID_SET:
      return action.currentCouponId;
    default:
      return state;
  }
};
