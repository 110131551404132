import { ActionInterface, ActionType } from '@/actions';

export interface AdditionalGamesState extends Array<number> {}

export const additionalGamesReducer = (
  state: AdditionalGamesState,
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.ADDITIONAL_GAME_TOGGLED:
      return state.indexOf(action.additionalGameId) >= 0
        ? state.filter((num) => num !== action.additionalGameId)
        : state.concat(action.additionalGameId);

    default:
      return state;
  }
};
