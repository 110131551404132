import LinkWrapper from '@/components/LinkWrapper';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import Image from 'next/image';

export const GameFooter = (props: {
  shortname?: string;
  title?: string;
  seoHeadline?: string;
  seoDescription?: string;
}) => {
  return (
    <div className="GameFooter">
      <div className="GameFooter__wrapper">
        <div className="GameFooter__columnContainer">
          <div className="GameFooter__seo">
            {props.seoHeadline && (
              <h2 className="GameFooter__headline">{props.seoHeadline}</h2>
            )}
            <p
              className="GameFooter__summary"
              dangerouslySetInnerHTML={replaceAbbreviations(
                props.seoDescription || ''
              )}
            />
          </div>
          <div className="GameFooter__doubleColumn">
            <div className="GameFooter__box">
              <Image
                alt=""
                src="/assets/images/logo-ab18-grey.svg"
                className="GameFooter__image"
                loading="lazy"
                decoding="async"
                width={40}
                height={50}
              />
              <span className="GameFooter__text GameFooter__text--bold">
                Spielteilnahme ab 18 Jahren!
              </span>
            </div>
            <div className="GameFooter__box">
              <span className="GameFooter__text GameFooter__text--small">
                Glücksspiel kann süchtig machen. Eine bundesweite Übersicht zu
                Hilfsangeboten gegen Glücksspielsucht finden Sie unter{' '}
                <a
                  className="GameFooter__link"
                  href="https://www.check-dein-spiel.de"
                  target="_blank"
                  title="Externer Link: https://www.check-dein-spiel.de (öffnet neues Fenster)"
                  rel="noopener noreferrer"
                >
                  www.check-dein-spiel.de
                </a>{' '}
                oder{' '}
                <a
                  className="GameFooter__link"
                  href="https://www.bundesweit-gegen-gluecksspielsucht.de/"
                  target="_blank"
                  title="Externer Link: https://www.bundesweit-gegen-gluecksspielsucht.de (öffnet neues Fenster)"
                  rel="noopener noreferrer"
                >
                  www.buwei.de
                </a>
                .
              </span>
            </div>
          </div>
        </div>
        <div className="GameFooter__nav">
          <LinkWrapper
            tabIndex={0}
            className="GameFooter__navLink"
            href="/impressum"
          >
            Impressum
          </LinkWrapper>
          <LinkWrapper
            tabIndex={0}
            className="GameFooter__navLink"
            href="/datenschutzerklaerung"
          >
            Datenschutzerklärung
          </LinkWrapper>
        </div>
      </div>
    </div>
  );
};
