import { ActionType } from '@/actions';
import { GameState } from '@/reducers/game';
import PersistedState from '@/store/PersistedState';
import { Middleware } from 'redux';

const gameStorageSync: (
  storage: PersistedState,
  gameId?: string | number
) => Middleware = (storage, gameId) => (store) => (next) => (action) => {
  const result = next(action);
  const { games, currentGameId } = store.getState();

  if (action.type === ActionType.STORAGE_RESET) {
    storage.deleteState().then();
  } else if (action.type.indexOf('GAME_FORM_LOADING_SET') === -1) {
    if (action.game) {
      const filteredGame = Object.keys(games).reduce(
        (_filteredGame, gameId) => {
          const game: GameState = games[gameId];
          const { currentStep, currentCouponId, ...rest } = game;
          _filteredGame[gameId] = +currentGameId === +gameId ? rest : game;
          return _filteredGame;
        },
        {}
      );

      storage.saveState({
        games: filteredGame,
        currentGameId: action.game || currentGameId,
      });
    }
  }
  return result;
};

export default gameStorageSync;
