/**
 * Checks if two arrays contain the same elements in any order.
 *
 * @param {readonly string[]} arr1 - An array of strings to compare.
 * @param {readonly string[]} arr2 - An array of strings to compare.
 * @return {boolean} Whether the two input arrays contain the same elements.
 */
export default function isEqual(
  arr1: readonly string[],
  arr2: readonly string[]
): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  if (set1.size !== set2.size) {
    return false;
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }

  return true;
}
