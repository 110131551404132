import { ActionType } from '@/actions';
import { GameFormVariant } from '@/components/GameFormVariant';
import {
  ActionGameForm,
  ActionGameFormError,
  ActionGameFormLoading,
  ActionGameFormSubsidiaryList,
  SubsidiaryObject,
} from './interfaces';

export const setGameFormVariant = (value: GameFormVariant): ActionGameForm => {
  return {
    type: ActionType.GAME_FORM_VARIANT_SET,
    value,
  };
};

export const setGameFormControl = (
  key: string,
  value: string,
  isTouched?: boolean,
  isValid?: boolean
): ActionGameForm => {
  return {
    type: ActionType.GAME_FORM_CONTROL_SET,
    key,
    value,
    isTouched,
    isValid,
  };
};

export const setGameFormLoading = (value: boolean): ActionGameFormLoading => {
  return {
    type: ActionType.GAME_FORM_LOADING_SET,
    value,
  };
};

export const setGameFormError = (value: number): ActionGameFormError => {
  return {
    type: ActionType.GAME_FORM_ERROR_SET,
    value,
  };
};

export const setGameFormSubsidiary = (value: string): ActionGameForm => {
  return {
    type: ActionType.GAME_FORM_SUBSIDIARY_SET,
    value,
  };
};

export const setGameFormSubsidiaryList = (
  value: SubsidiaryObject[]
): ActionGameFormSubsidiaryList => {
  return {
    type: ActionType.GAME_FORM_SUBSIDIARY_LIST_SET,
    value,
  };
};
