const deepMerge = (target: object, source: object) => {
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object && target[key]) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  });
  return { ...target, ...source };
};

export default deepMerge;
