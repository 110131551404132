import { ActionInterface, ActionType } from '@/actions';

export interface CouponNumberGroupState extends Array<number> {}

export const couponNumberGroupReducer = (
  state: CouponNumberGroupState = [],
  action: ActionInterface
): CouponNumberGroupState => {
  switch (action.type) {
    case ActionType.COUPON_NUMBER_TOGGLED:
      return state.indexOf(action.value) >= 0
        ? state.filter((num) => num !== action.value)
        : state.concat(action.value);

    case ActionType.COUPON_NUMBERS_SET:
      if (
        state.length >= action.values.length ||
        action.values.length === 0 ||
        state.length === 0
      ) {
        return action.values;
      }
      const values = [...action.values].slice(state.length);
      return [...state, ...values];

    default:
      return state;
  }
};
