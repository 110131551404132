import { ActionInterface, ActionType } from '@/actions';

export interface PlayNumberState extends Array<number> {}

export const playNumberReducer = (
  state: PlayNumberState = [],
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.PLAY_NUMBER_SET:
      return action.values;

    default:
      return state;
  }
};
