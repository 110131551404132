import LinkWrapper from '@/components/LinkWrapper';
import Modal, {
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@/components/Modal';
import React from 'react';

const ModalPasswordForgetLinks: React.FC<
  React.PropsWithChildren<ModalProps>
> = ({ id }) => {
  const dataLinks = [
    {
      title: 'Baden-Württemberg',
      href: 'https://www.lotto-bw.de/passwort-vergessen',
    },
    {
      title: 'Berlin',
      href: 'https://www.lotto-berlin.de/passwort-vergessen',
    },
    {
      title: 'Brandenburg',
      href: 'https://www.lotto-brandenburg.de',
    },
    {
      title: 'Bayern',
      href: 'https://www.lotto-bayern.de/passwort-vergessen',
    },
    {
      title: 'Bremen',
      href: 'https://www.lotto-bremen.de/passwort-vergessen',
    },
    {
      title: 'Hamburg',
      href: 'https://www.lotto-hh.de',
    },
    {
      title: 'Hessen',
      href: 'https://www.lotto-hessen.de/login/passwort-vergessen',
    },
    {
      title: 'Mecklenburg-Vorpommern',
      href: 'https://www.lottomv.de/passwort-vergessen',
    },
    {
      title: 'Niedersachsen',
      href: 'https://www.lotto-niedersachsen.de/spielkonto/passwort-vergessen',
    },
    {
      title: 'Nordrhein-Westfalen',
      href: 'https://www.westlotto.de/passwort-vergessen/passwort-vergessen.html',
    },
    {
      title: 'Rheinland-Pfalz',
      href: 'https://www.lotto-rlp.de/passwort-vergessen',
    },
    {
      title: 'Saarland',
      href: 'https://www.saartoto.de/passwort-vergessen',
    },
    {
      title: 'Sachsen',
      href: 'https://www.sachsenlotto.de/portal/user/login/forgotpasswordshow.do',
    },
    {
      title: 'Sachsen-Anhalt',
      href: 'https://www.lottosachsenanhalt.de/passwort-vergessen',
    },
    {
      title: 'Schleswig-Holstein',
      href: 'https://www.lotto-sh.de/passwort-vergessen',
    },
    {
      title: 'Thüringen',
      href: 'https://www.lotto-thueringen.de/login/reset-password.html',
    },
  ];

  return (
    <Modal id={id}>
      <ModalHeader>
        <span>Passwort vergessen</span>
      </ModalHeader>
      <ModalContent>
        <h4>Bitte wählen Sie Ihr Bundesland:</h4>
        <ul>
          {dataLinks.map((item, index) => (
            <li key={index}>
              {/* FIXME this should be a button */}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <LinkWrapper
                href={item.href}
                className="Modal__link"
                target="_blank"
                tabIndex={0}
                title={`Externer Link: ${item.href} (öffnet neues Fenster)`}
                onClick={($event) => {
                  $event.preventDefault();
                  Modal.close(id);
                  window.open(item.href, '_blank');
                }}
              >
                {item.title}
              </LinkWrapper>
            </li>
          ))}
        </ul>
      </ModalContent>
    </Modal>
  );
};

export default ModalPasswordForgetLinks;
