import store from '@/store/store';
import React from 'react';
import { Provider } from 'react-redux';

class GameProvider extends React.Component<{ children?: React.ReactNode }> {
  render() {
    return (
      <Provider store={store}>
        <main>{this.props.children || null}</main>
      </Provider>
    );
  }
}

export default GameProvider;
