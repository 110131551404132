import { ActionInterface, ActionType } from '@/actions';
import { ActionGameForm } from '@/actions/gameForm/interfaces';
import {
  GameFormState,
  gameFormReducer,
  initialGameFormState,
} from '@/reducers/gameForm';
import { GamesState, gamesReducer, initialGamesState } from '@/reducers/games';
import deepMerge from '@/utilities/deepMerge';

export interface AppState {
  currentGameId: string;
  games: GamesState;
  gameForm?: GameFormState;
}

const initialAppState = {
  currentGameId: '',
  games: initialGamesState,
  gameForm: initialGameFormState,
};

export const reducers = (
  state: AppState = initialAppState,
  action: ActionInterface
) => {
  const _state = {
    ...state,
    currentGameId: action.game || state.currentGameId,
  };

  return action.type === ActionType.STATE_SET && action.state
    ? deepMerge(_state, action.state)
    : {
        ..._state,
        games: gamesReducer(_state.games, action),
        gameForm: gameFormReducer(_state.gameForm, action as ActionGameForm),
      };
};
