import { subsidiaryConfig } from "@/utilities/subsidiaries";
import { createHmac } from "crypto";
import MarketingId from "./MarketingId";

const getMarketingId = async (request) => {
  const marketingId = new MarketingId(
    request.campaign,
    request.network,
    request.partner,
    request.advertisingMaterial,
    request.additionalInfo
  );
  return await marketingId.getMarketingId();
}

const getGameFormApiContent = async (endpoint) => {
  const requestHeaders: HeadersInit = new Headers();
  if (process.env.SPIELSCHEIN_API_KEY_NAME && process.env.SPIELSCHEIN_API_KEY_VALUE) {
    requestHeaders.set(process.env.SPIELSCHEIN_API_KEY_NAME, process.env.SPIELSCHEIN_API_KEY_VALUE);
  }
  const authResponse = await fetch(endpoint, {
    method: 'GET',
    headers: requestHeaders,
  });
  if (authResponse?.status === 200) {
    return await authResponse.text();
  }
  return;
};
const getHmacSecret = async (blId) => {
  return await getGameFormApiContent(
    process.env.SPIELSCHEIN_API_REST_URL + '/company/' + blId + '/secret'
  );
};
export const getEndpointUrl = async (blId) => {
  const response = await getGameFormApiContent(
    process.env.SPIELSCHEIN_API_REST_URL + '/url/' + blId
  );
  if (response) {
    const data = JSON.parse(response);
    return data?.DESKTOP;
  }
  return;
};

export const createSignedData = async (endpointUrl, data, timestamp, blId) => {
  // secret
  const hmacSecret = await getHmacSecret(blId);
  if (!hmacSecret) {
    return;
  }
  const urlToSign = endpointUrl.replace(/\?(.*)$/, "");
  const clearText = urlToSign + data + timestamp;
  const signature = createHmac('sha1', hmacSecret)
    .update(clearText)
    .digest('base64url');
  return (
    endpointUrl + (endpointUrl.includes('?') ? '&' : '?') + 'currenttimestamp=' + timestamp + '&signature=' + signature
  );
};

const getNumberSetType = (value) => {
  return value.split(" ").length;
}
const generateTicketData = (game) => {
  let text = '<ticket>';
  if (game) {
    text += `<spiel `;
    if (!game.subscription) text += `laufzeit="${game.term}" `;
    if (game.ticketNumber) text += `losnummer="${game.ticketNumber}" `;
    if (
      (game.type === 1 &&
        (game.matchDay === 4 || game.matchDay === 5 || game.matchDay === 6)) ||
      (game.type === 4 &&
        (game.matchDay === 1 || game.matchDay === 2 || game.matchDay === 3))
    ) {
      text += `spieltag="${game.matchDay}" `;
    }
    text += `typ="${game.type}"`;
    if (game.subscription) text += ` abo="true"`;
    text += `>`;

    for (let sideGame of game.sideGames) {
      text += `<zusatzspiel typ="${sideGame.type}"/>`;
    }
    let rowCnt = 0;
    for (let gameRow of game.rows) {
      text += `<tippreihe nr="${++rowCnt}"`;
      if (gameRow.value) text += ` wert="${gameRow.value}"`;
      text += `>`;

      for (let numberSet of gameRow.numberSets) {
        text += `<zahlen`;
        if (game.type != 4) text += ` typ="${getNumberSetType(numberSet.value)}"`;
        text += `>${numberSet.value}</zahlen>`;
      }
      text += `</tippreihe>`;
    }

    text += `</spiel>`;
  }
  text += '</ticket>';
  return text;
};
const sendEmptyTicket = (subsidiary: string) => {
  const sub = findSubsidiaryById(subsidiary);
  if (!sub) return false;
  return sub['sendEmptyTicket'];
}
export const findSubsidiaryById = (subsidiary: string) => {
  const result = subsidiaryConfig.find((config) => config.id === subsidiary);
  if (!result) return;
  return result;
}
export const findSubsidiaryByPlz = (subsidiary: string) => {
  const result = subsidiaryConfig.find((config) => config.plz === subsidiary);
  if (!result) return;
  return result;
}
export const findSubsidiaryByKey = (subsidiary: string) => {
  const result = subsidiaryConfig.find((config) => config.key === subsidiary);
  if (!result) return;
  return result;
}

export const generateXmlData = async (data, bundesland) => {
  let xmlString = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>';
  xmlString +=
    '<request typ="' +
    data.formType +
    '" xmlns="http://www.lotto.de/llg/registerRequest">';
  xmlString += '<marketingid>' + await getMarketingId(data) + '</marketingid>';

  if (data.campaign) xmlString += `<kampagne>${data.campaign}</kampagne>`;
  if (data.network) xmlString += `<netzwerk>${data.network}</netzwerk>`;
  if (data.partner) xmlString += `<partner>${data.partner}</partner>`;
  if (data.advertisingMaterial)
    xmlString += `<werbemittel>${data.advertisingMaterial}</werbemittel>`;
  if (data.additionalInfo)
    xmlString += `<zusatzinfos>${data.additionalInfo}</zusatzinfos>`;
  if (data.game || sendEmptyTicket(bundesland)) {
    xmlString += generateTicketData(data.game);
  }

  xmlString += '</request>';
  return xmlString;
};
