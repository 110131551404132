import { GameFormProps } from '@/components/GameForm';

export default class GameFormQueryParamsParser {
  gameFormProps: GameFormProps;
  queryKey: string;

  public static removeParamsFromUrl() {
    (window as any).history.pushState(
      {},
      document.title,
      window.location.pathname
    );
  }

  constructor(gameFormProps: GameFormProps) {
    this.gameFormProps = gameFormProps;
    this.queryKey = '__gamedata';
  }

  init() {
    const search = new URLSearchParams(window.location.search);
    const value = search.get(this.queryKey);
    if (value) {
      this.gameState(value);
    }
  }

  private gameState: (queryParamValue: string) => void = (
    queryParamValue: string
  ) => {
    try {
      const gameData = JSON.parse(queryParamValue);

      if (gameData && this.gameFormProps.onStateUpdate) {
        this.gameFormProps.onStateUpdate(gameData);
      }

      GameFormQueryParamsParser.removeParamsFromUrl();
    } catch (e) {
      console.warn('No proper game data from url params');
    }
  };
}
