import { GameFormData, GameFormDataRow } from '@/components/GameForm';
import { GameConfig } from '@/config/game';
import { eurojackpot } from '@/config/game/eurojackpot';
import { lotto6aus49 } from '@/config/game/lotto6aus49';
import { GameState } from '@/reducers/game';
import isEqual from '@/utilities/isEqual';

const getMatchDayOld = (selectedDrawDays) => {
  return selectedDrawDays.reduce(
    (prev, current) => prev + (current === 'Mittwoch' ? 1 : 2),
    0
  );
};

const getMatchDayNew = (type: number, selectedDrawDays: readonly string[]) => {
  switch (type) {
    case lotto6aus49.id:
      if (isEqual(selectedDrawDays, ['Mittwoch'])) {
        return 1;
      }
      if (isEqual(selectedDrawDays, ['Samstag'])) {
        return 2;
      }
      if (isEqual(selectedDrawDays, ['Mittwoch', 'Samstag'])) {
        return 3;
      }
      throw new Error('invalid draw days for lotto6aus49');
    case eurojackpot.id:
      if (isEqual(selectedDrawDays, ['Dienstag'])) {
        return 4;
      }
      if (isEqual(selectedDrawDays, ['Freitag'])) {
        return 5;
      }
      if (isEqual(selectedDrawDays, ['Dienstag', 'Freitag'])) {
        return 6;
      }
      throw new Error('invalid draw days for eurojackpot');
    default:
      return getMatchDayOld(selectedDrawDays);
  }
};

// see https://jira.tln-hannover.de/browse/LOTREL-579
const getMatchDay = (type: number, selectedDrawDays: readonly string[]) => {
  return getMatchDayNew(type, selectedDrawDays);
};

export function mapGameToSubmitData(
  state: GameState,
  gameConfig?: GameConfig
): GameFormData | null {
  if (!gameConfig) {
    return null;
  }

  const rows = gameConfig.couponGroup
    ? Object.keys(state.couponGroup)
        .reduce((_rows: GameFormDataRow[], couponId) => {
          const couponNumbers = state.couponGroup[couponId];
          const groupCollection: Array<{ value: string }> = [];

          for (const groupId in couponNumbers) {
            if (couponNumbers.hasOwnProperty(groupId)) {
              const groupNumbers = couponNumbers[groupId];

              if (
                gameConfig.couponGroup &&
                groupNumbers.length >=
                  gameConfig.couponGroup.numberGroups[groupId].minCrossed &&
                groupNumbers.length <=
                  gameConfig.couponGroup.numberGroups[groupId].maxCrossed
              ) {
                groupCollection.push({
                  value: groupNumbers.join(' '),
                });
              }
            }
          }
          _rows.push({
            numberSets: groupCollection,
            value: +state.selectedDrawRate * 100,
          });
          return _rows;
        }, [])
        .filter((row) => row.numberSets.length)
    : [];

  const sideGames = state.selectedAdditionalGames.reduce(
    (prev, current) => [...prev, { type: current }],
    []
  );
  const matchDay = getMatchDay(gameConfig.id, state.selectedDrawDays);
  const subscription =
    (state.selectedWeeklySubscription as boolean) || undefined;
  const term = +state.selectedDrawNumber * +state.selectedWeeksNumber;
  const ticketNumber = state.selectedPlayNumber
    ? state.selectedPlayNumber.join('')
    : '';

  return {
    term,
    ticketNumber,
    matchDay,
    type: gameConfig.id,
    rows,
    sideGames,
    subscription,
  };
}
