import Modal, {
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@/components/Modal';
import React from 'react';

const ModalRegisterInfo: React.FC<React.PropsWithChildren<ModalProps>> = ({
  id,
}) => {
  return (
    <Modal id={id}>
      <ModalHeader>
        <span>Registrierung bei Ihrer Landeslotteriegesellschaft</span>
      </ModalHeader>
      <ModalContent>
        <p>
          Bitte geben Sie E-Mail ein und wählen Sie das Bundesland aus, in dem
          Sie Ihren Wohnsitz haben. Sie werden auf die Webseite der jeweiligen
          Landeslotteriegesellschaft weitergeleitet, wo Sie die Registrierung
          und die Abgabe Ihres Spielscheins abschließen können.
        </p>
      </ModalContent>
    </Modal>
  );
};

export default ModalRegisterInfo;
