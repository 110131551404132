export const subsidiaryConfig = [
  {
    id: '01',
    plz: '40210',
    displayName: 'Nordrhein-Westfalen',
    key: 'NW',
    sendEmptyTicket: false
  },
  {
    id: '02',
    plz: '80333',
    displayName: 'Bayern',
    key: 'BY',
    sendEmptyTicket: false
  },
  {
    id: '03',
    plz: '70173',
    displayName: 'Baden-Württemberg',
    key: 'BW',
    sendEmptyTicket: true
  },
  {
    id: '04',
    plz: '30159',
    displayName: 'Niedersachsen',
    key: 'NI',
    sendEmptyTicket: true
  },
  {
    id: '05',
    plz: '65189',
    displayName: 'Hessen',
    key: 'HE',
    sendEmptyTicket: false
  },
  {
    id: '06',
    plz: '55116',
    displayName: 'Rheinland-Pfalz',
    key: 'RP',
    sendEmptyTicket: false
  },
  {
    id: '07',
    plz: '10115',
    displayName: 'Berlin',
    key: 'BE',
    sendEmptyTicket: false
  },
  {
    id: '08',
    plz: '24103',
    displayName: 'Schleswig-Holstein',
    key: 'SH',
    sendEmptyTicket: false
  },
  {
    id: '09',
    plz: '20095',
    displayName: 'Hamburg',
    key: 'HH',
    sendEmptyTicket: false
  },
  {
    id: '10',
    plz: '01067',
    displayName: 'Sachsen',
    key: 'SN',
    sendEmptyTicket: false
  },
  {
    id: '11',
    plz: '39106',
    displayName: 'Sachsen-Anhalt',
    key: 'ST',
    sendEmptyTicket: false
  },
  {
    id: '12',
    plz: '66117',
    displayName: 'Saarland',
    key: 'SL',
    sendEmptyTicket: false
  },
  {
    id: '13',
    plz: '14467',
    displayName: 'Brandenburg',
    key: 'BB',
    sendEmptyTicket: true
  },
  {
    id: '14',
    plz: '99084',
    displayName: 'Thüringen',
    key: 'TH',
    sendEmptyTicket: false
  },
  {
    id: '15',
    plz: '28195',
    displayName: 'Bremen',
    key: 'HB',
    sendEmptyTicket: false
  },
  {
    id: '16',
    plz: '17214',
    displayName: 'Mecklenburg-Vorpommern',
    key: 'MV',
    sendEmptyTicket: false
  }
];