import { ActionInterface } from '@/actions';
import {
  couponNumberGroupReducer,
  CouponNumberGroupState,
} from '@/reducers/couponNumberGroup';

export interface CouponState {
  [group: string]: CouponNumberGroupState;
}

export const couponReducer = (
  state: CouponState = {},
  action: ActionInterface
) => {
  return {
    ...state,
    [action.group]: couponNumberGroupReducer(state[action.group], action),
  };
};
