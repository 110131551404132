import { ActionInterface, ActionType } from '@/actions';

export type WeeksNumberState = number;

export const weeksNumberReducer = (
  state: WeeksNumberState,
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.WEEKS_NUMBER_SET:
      return action.weeksNumber;

    default:
      return state;
  }
};
