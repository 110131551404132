import { setState } from '@/actions';
import { reducers } from '@/reducers';
import gameFormValidation from '@/store/gameFormValidation';
import gameStorageSync from '@/store/gameStorageSync';
import PersistedState from '@/store/PersistedState';
import { restoreState } from '@/store/restoreState';
import {
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from 'redux';

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const storage: PersistedState = new PersistedState();

const enhancer: StoreEnhancer = composeEnhancers(
  applyMiddleware(gameFormValidation, gameStorageSync(storage))
);

const store: Store = createStore(reducers, {}, enhancer);

export const fetchGameState = async (
  gameId?: string | number
): Promise<void> => {
  try {
    const data: unknown = (await storage.loadState()) as unknown;
    const restoredState = restoreState(data);
    if (restoredState) {
      store.dispatch(setState(restoredState, `${gameId}`));
    }
  } catch (error) {
    error.log('Error fetchGameState:', error);
  }
};

export default store;
