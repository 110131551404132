import { ActionType } from '@/actions';
import { ActionGameFormControl } from '@/actions/gameForm/interfaces';
import { VALIDATIONS } from '@/config/forms/validations';

const isValidFormControl = (action: ActionGameFormControl) =>
  VALIDATIONS[action.key].regex.test(action.value);

const gameFormValidation = (store) => (next) => (action) => {
  if (action.type === ActionType.GAME_FORM_CONTROL_SET) {
    const isValid =
      typeof action.isValid !== 'undefined'
        ? action.isValid
        : VALIDATIONS.hasOwnProperty(action.key)
        ? isValidFormControl(action)
        : true;

    return next({
      ...action,
      isValid,
    });
  }

  return next(action);
};

export default gameFormValidation;
