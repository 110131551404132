const setFocusIfElementExists = (selector): boolean => {
  const elem = document.querySelector(selector);
  if (elem) {
    (elem as HTMLElement).focus();
    return true;
  }
  return false;
};

export default setFocusIfElementExists;
