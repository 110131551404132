import { ActionInterface, ActionType } from '@/actions';
import { GameStep } from '@/config/game/steps';

export type CurrentStepState = GameStep;

export function currentStepReducer(
  state: CurrentStepState,
  action: ActionInterface
) {
  switch (action.type) {
    case ActionType.CURRENT_STEP_SET:
      return action.value;
    default:
      return state;
  }
}
