import { ActionInterface } from '@/actions';
import { couponReducer, CouponState } from '@/reducers/coupon';

export interface CouponGroupState {
  [coupon: string]: CouponState;
}

export const couponGroupReducer = (
  state: CouponGroupState = {},
  action: ActionInterface
) => {
  return action.coupon !== undefined && action.group !== undefined
    ? {
        ...state,
        [action.coupon]: couponReducer(state[action.coupon], action),
      }
    : state;
};
