import { FormControlData } from '@/components/FormControlText';
import { GameFormVariant } from '@/components/GameFormVariant';
import { ActionType } from '@/actions';
import {
  ActionGameForm,
  ActionGameFormControl,
  ActionGameFormError,
  ActionGameFormLoading,
  ActionGameFormSubsidiaryList,
  SubsidiaryObject,
} from '@/actions/gameForm/interfaces';

export interface GameFormState {
  email: FormControlData;
  password: FormControlData;
  plz: FormControlData;
  subsidiary: string;
  subsidiaryList: SubsidiaryObject[];
  variant: GameFormVariant;
  isLoading: boolean;
  errorCode: number | null;
}

const initialFormControl = { value: '', isValid: false, isTouched: false };

export const initialGameFormState: GameFormState = {
  email: initialFormControl,
  password: initialFormControl,
  plz: initialFormControl,
  subsidiary: '',
  subsidiaryList: [],
  variant: GameFormVariant.Login,
  isLoading: false,
  errorCode: null,
};

export const gameFormReducer = (
  state: GameFormState = initialGameFormState,
  action:
    | ActionGameForm
    | ActionGameFormControl
    | ActionGameFormLoading
    | ActionGameFormError
    | ActionGameFormSubsidiaryList
): GameFormState => {
  const value = action.value;
  switch (action.type) {
    case ActionType.GAME_FORM_CONTROL_SET:
      const actionGameFormControl = action as ActionGameFormControl;
      const isTouched =
        typeof actionGameFormControl.isTouched !== 'undefined'
          ? actionGameFormControl.isTouched
          : state[action.key].isTouched;

      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          value: value as string,
          isValid: actionGameFormControl.isValid,
          isTouched,
        },
        errorCode: null,
        subsidiary: '',
        subsidiaryList: [],
      };

    case ActionType.GAME_FORM_ERROR_SET:
      return {
        ...state,
        errorCode: value as number,
      };

    case ActionType.GAME_FORM_LOADING_SET:
      return {
        ...state,
        isLoading: value as boolean,
      };

    case ActionType.GAME_FORM_SUBSIDIARY_SET:
      return {
        ...state,
        subsidiary: value as string,
      };

    case ActionType.GAME_FORM_SUBSIDIARY_LIST_SET:
      return {
        ...state,
        subsidiaryList: value as SubsidiaryObject[],
      };

    case ActionType.GAME_FORM_VARIANT_SET:
      return {
        ...state,
        errorCode: null,
        variant: action.value as GameFormVariant,
      };

    default:
      return state;
  }
};
