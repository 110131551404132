import { WidgetInstance } from 'friendly-challenge';
import React, { Component } from 'react';

interface FriendlyCaptchaProps {
  ref: React.RefObject<FriendlyCaptcha>;
  callbackDone: (solution: string) => any;
}

class FriendlyCaptcha extends Component<FriendlyCaptchaProps> {
  container = React.createRef<HTMLDivElement>();
  widget: WidgetInstance | null = null;

  errorCallback = (err: any) => {
    console.log('There was an error when trying to solve the Captcha.');
    console.log(err);
  };

  componentDidMount() {
    if (!this.widget && this.container.current) {
      this.widget = new WidgetInstance(this.container.current, {
        startMode: 'focus',
        doneCallback: this.props.callbackDone,
        errorCallback: this.errorCallback,
      });
    }
  }

  public resetCaptcha() {
    if (this.widget) {
      this.widget.reset();
    }
  }

  componentWillUnmount() {
    if (this.widget) {
      this.widget.reset();
    }
  }

  render() {
    return (
      <div
        ref={this.container}
        className="frc-captcha GameForm__captcha"
        data-sitekey={process.env.NEXT_PUBLIC_FRIENDLY_CAPTCHA_SITEKEY}
        data-lang="de"
        data-puzzle-endpoint="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
      />
    );
  }
}

export default FriendlyCaptcha;
