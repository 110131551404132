import classnames from 'classnames';

export interface FormControlData {
  value: string;
  isValid: boolean;
  isTouched: boolean;
}

export enum FormControlTextType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  TextArea = 'textarea',
}

export const FormControlText = ({
  id,
  name,
  type = FormControlTextType.Text,
  required,
  disabled,
  className,
  label,
  onChange,
  value,
  errorMessage = 'Bitte überprüfen Sie Ihre Angaben.',
  isValid,
  pattern,
  maxLength,
  onlyDigits,
  isTouched = false,
  autoComplete,
  errorId,
}: {
  id: string;
  value: string;
  name?: string;
  type?: FormControlTextType;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  isTouched?: boolean;
  isValid?: boolean;
  label?: string;
  onlyDigits?: boolean;
  maxLength?: number;
  pattern?: string;
  required?: boolean;
  onChange: (id: string, value: string, isTouched?: boolean) => void;
  autoComplete?: string;
  errorId?: string;
}) => {
  const handleOnChange = (touched?: boolean) => ($event) => {
    const _value = $event.target.value;

    if (
      (maxLength && _value.length > maxLength) ||
      (onlyDigits && !/^[\d]*$/.test(_value))
    ) {
      return;
    }

    onChange($event.target.id, _value, touched);
  };

  const fieldProps = {
    id,
    name: name || id,
    type,
    className: classnames('FormControlText__field', className),
    value,
    disabled,
    required,
    pattern,
    onChange: handleOnChange(undefined),
    onBlur: handleOnChange(true),
    autoComplete,
    'aria-describedby': errorId,
  };

  const hasError = !isValid && isTouched;

  return (
    <div
      className={classnames('FormControlText', {
        'FormControlText--error': hasError,
      })}
    >
      {label && <label htmlFor={id}>{label}</label>}

      {type !== FormControlTextType.TextArea ? (
        <input {...fieldProps} />
      ) : (
        <textarea {...fieldProps} />
      )}

      {hasError && (
        <div
          className="FormControlText__error-message"
          id={errorId}
          aria-live="assertive"
          aria-atomic="true"
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};
