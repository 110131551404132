import { AppState } from '@/reducers';
import { IPersistedState, StorageStateType } from '@/store/interfaces';

export default class PersistedStateWithLS implements IPersistedState {
  public loadState = async (storageKey: string): Promise<StorageStateType> => {
    try {
      const serializedState = localStorage.getItem(storageKey);

      if (serializedState) {
        return JSON.parse(serializedState) as Partial<AppState>;
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  public saveState = async (
    storageKey: string,
    state: Partial<AppState>
  ): Promise<void> => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(storageKey, serializedState);
    } catch (error) {
      console.error('Error storage - can not save data. ', error);
    }
  };

  public deleteState = async (storageKey: string): Promise<void> => {
    localStorage.removeItem(storageKey);
  };
}
