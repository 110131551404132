import { AppState } from '@/reducers';

export enum ActionType {
  INIT = '@@INIT',
  COUPON_NUMBERS_SET = 'COUPON_NUMBERS_SET',
  COUPON_NUMBER_TOGGLED = 'COUPON_NUMBER_TOGGLED',
  PLAY_NUMBER_SET = 'PLAY_NUMBER_SET',
  DRAW_DAYS_SET = 'DRAW_DAYS_SET',
  WEEKS_NUMBER_SET = 'WEEKS_NUMBER_SET',
  WEEKLY_SUBSCRIPTION_TOGGLE = 'WEEKLY_SUBSCRIPTION_TOGGLE',
  DRAW_NUMBER_SET = 'DRAW_NUMBER_SET',
  DRAW_RATE_SET = 'DRAW_RATE_SET',
  ADDITIONAL_GAME_TOGGLED = 'ADDITIONAL_GAME_TOGGLED',
  GAME_RESET = 'GAME_RESET',
  GAME_FORM_CONTROL_SET = 'GAME_FORM_CONTROL_SET',
  GAME_FORM_LOADING_SET = 'GAME_FORM_LOADING_SET',
  GAME_FORM_ERROR_SET = 'GAME_FORM_ERROR_SET',
  GAME_FORM_SUBSIDIARY_SET = 'GAME_FORM_SUBSIDIARY_SET',
  GAME_FORM_SUBSIDIARY_LIST_SET = 'GAME_FORM_SUBSIDIARY_LIST_SET',
  GAME_FORM_VARIANT_SET = 'GAME_FORM_VARIANT_SET',
  CURRENT_STEP_SET = 'CURRENT_STEP_SET',
  CURRENT_COUPON_ID_SET = 'CURRENT_COUPON_ID_SET',
  STATE_SET = 'STATE_SET',
  STORAGE_RESET = 'STORAGE_RESET',
}

export interface ActionInterface {
  type: ActionType;
  [key: string]: any;
}

export type ActionSetStateFunction = (
  state: Partial<AppState>,
  game?: string
) => void;
export type ActionResetGameStateFunction = (game: string) => void;

export const setState = (state: AppState, game?: string): ActionInterface => {
  return {
    type: ActionType.STATE_SET,
    state,
    game,
  };
};

export const resetGameState = (game: string): ActionInterface => {
  return {
    type: ActionType.GAME_RESET,
    game,
  };
};

export const resetGameStorage = (game: string): ActionInterface => {
  return {
    type: ActionType.STORAGE_RESET,
    game,
  };
};
