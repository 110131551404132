import { ActionInterface, ActionType } from '@/actions';

export type DrawNumberState = number;

export const drawNumberReducer = (
  state: DrawNumberState,
  action: ActionInterface
) => {
  switch (action.type) {
    case ActionType.DRAW_NUMBER_SET:
      return action.drawNumber;

    default:
      return state;
  }
};
