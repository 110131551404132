import {
  ActionResetGameStateFunction,
  ActionSetStateFunction,
} from '@/actions';
import {
  ActionGameFormControlFunction,
  ActionGameFormErrorFunction,
  ActionGameFormFunction,
  ActionGameFormLoadingFunction,
  ActionGameFormSubsidiaryListFunction,
  ActionGameFormVariantFunction,
  SubsidiaryObject,
} from '@/actions/gameForm/interfaces';
import { FormControlData } from '@/components/FormControlText';
import FriendlyCaptcha from '@/components/FriendlyCaptcha';
import GameFormLogin from '@/components/GameFormLogin';
import GameFormRegister from '@/components/GameFormRegister';
import { GameFormVariant } from '@/components/GameFormVariant';
import StepTransition, { TransitionVariant } from '@/components/StepTransition';
import API from '@/config/api';
import { GameConfig } from '@/config/game';
import GameFormQueryParamsParser from '@/helpers/GameFormQueryParamsParser';
import { findSubsidiaryByPlz } from '@/helpers/GameFormUtils';
import MarketingParams from '@/helpers/MarketingParams';
import { getGameById } from '@/helpers/getGameById';
import { mapGameToSubmitData } from '@/helpers/mapGameToSubmitData';
import { GameState } from '@/reducers/game';
import { fetchGameState } from '@/store/store';
import REDEMPTION_FORM_TYPE_IDS from '@/utilities/formTypeIds';
import { DataLayerEvent, pushGameToDatalayer } from '@/utilities/pushDatalayer';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';

export interface GameFormProps {
  currentGameId?: string | number;
  gameState: GameState;
  initialVariant: GameFormVariant;
  variant: GameFormVariant;
  email: FormControlData;
  password: FormControlData;
  plz: FormControlData;
  subsidiary: string;
  subsidiaryList: SubsidiaryObject[];
  isLoading: boolean;
  errorCode: number;
  onChangeFormVariant: ActionGameFormVariantFunction;
  onChangeFormControl: ActionGameFormControlFunction;
  onChangeSubsidiary: ActionGameFormFunction;
  onChangeSubsidiaryList: ActionGameFormSubsidiaryListFunction;
  onChangeLoading: ActionGameFormLoadingFunction;
  onChangeError: ActionGameFormErrorFunction;
  onResetGameStorage: ActionResetGameStateFunction;
  onClickBackButton: () => void;
  onStateUpdate?: ActionSetStateFunction;
  doneCallback: Function;
}

export interface GameFormData {
  term: number;
  ticketNumber: string;
  matchDay: number;
  type: number;
  rows: GameFormDataRow[];
  sideGames: Array<{ type: number }>;
  subscription?: boolean;
}

export interface GameFormDataRow {
  value?: number;
  numberSets: Array<{
    value: string;
  }>;
}

class GameForm extends React.Component<GameFormProps> {
  captchaRefLogin = React.createRef<FriendlyCaptcha>();
  captchaRefRegister = React.createRef<FriendlyCaptcha>();
  static ControlKeys = {
    [GameFormVariant.Login]: ['email'],
    [GameFormVariant.Register]: ['email', 'plz'],
  };
  dataInput: HTMLInputElement;
  gameForm: HTMLFormElement;
  state: {
    loaded: boolean;
    bundesland: string;
    solution: string;
    isCaptchaEnabled: boolean;
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      bundesland: '',
      solution: '',
      isCaptchaEnabled: process.env.NEXT_PUBLIC_ENABLE_CAPCHTA === 'true',
    };
  }

  componentDidMount() {
    this.setState({ ...this.state, loaded: true });
    if (this.props.initialVariant) {
      fetchGameState().then(() => {
        const gameFormQueryParamsParser = new GameFormQueryParamsParser(
          this.props
        );
        gameFormQueryParamsParser.init();
        this.props.onChangeFormVariant(this.props.initialVariant);
      });
    }
  }

  render() {
    const {
      email,
      password,
      plz,
      subsidiary,
      subsidiaryList,
      isLoading,
      errorCode,
    } = this.props;

    const props = {
      email,
      isLoading,
      errorCode,
      onChange: this.handleOnChange,
      onChangeFormVariant: this.handleOnChangeFormVariant,
    };

    return (
      <form
        method="post"
        className="GameForm"
        id={`${this.props.variant}-form`}
        onSubmit={this.handleOnSubmit}
        ref={(form: HTMLFormElement) => {
          this.gameForm = form;
        }}
        noValidate={true}
        target="_parent"
      >
        <noscript>
          <p>Für die Ausführung dieser Webseite ist JavaScript erforderlich</p>
        </noscript>
        <TransitionGroup>
          {this.props.variant === GameFormVariant.Register && (
            <StepTransition variant={TransitionVariant.Zoom}>
              <fieldset
                aria-label="Registrierung"
                disabled={!this.state.loaded}
              >
                <GameFormRegister
                  plz={plz}
                  onSelect={(event) => function () {}}
                  disabled={this.state.isCaptchaEnabled && !this.state.solution}
                  subsidiary={subsidiary}
                  subsidiaryList={subsidiaryList}
                  isCaptchaEnabled={this.state.isCaptchaEnabled}
                  captchaRef={this.captchaRefRegister}
                  callbackDone={this.doneCallback}
                  onChangeSubsidiary={this.handleOnChangeSubsidiary}
                  {...props}
                />
              </fieldset>
            </StepTransition>
          )}

          {this.props.variant === GameFormVariant.Login && (
            <StepTransition variant={TransitionVariant.Zoom}>
              <fieldset
                aria-label="Login / Anmeldung"
                disabled={!this.state.loaded}
              >
                <GameFormLogin
                  onSelect={(event) =>
                    this.setState({ bundesland: event.target.value })
                  }
                  disabled={
                    !this.state.bundesland ||
                    (this.state.isCaptchaEnabled && !this.state.solution)
                  }
                  password={password}
                  isCaptchaEnabled={this.state.isCaptchaEnabled}
                  captchaRef={this.captchaRefLogin}
                  callbackDone={this.doneCallback}
                  {...props}
                />
              </fieldset>
            </StepTransition>
          )}
        </TransitionGroup>

        <input
          type="hidden"
          name="data"
          ref={(input: HTMLInputElement) => {
            this.dataInput = input;
          }}
        />
      </form>
    );
  }

  private get gameConfig(): GameConfig | null {
    const { currentGameId } = this.props;
    return (currentGameId && getGameById(currentGameId)) || null;
  }

  private get currentControlKeys(): string[] {
    return GameForm.ControlKeys[this.props.variant];
  }

  private get isFormValid() {
    this.currentControlKeys.forEach((key) => {
      this.props.onChangeFormControl(key, this.props[key].value, true);
    });

    return this.currentControlKeys.every((key) => this.props[key].isValid);
  }

  private resetCurrentGameStorage() {
    if (this.props.currentGameId) {
      this.props.onResetGameStorage(this.props.currentGameId as any);
    }
  }

  private handleOnSubmit = async ($event: React.FormEvent<EventTarget>) => {
    try {
      $event.preventDefault();

      if (!this.isFormValid) {
        return;
      }

      this.props.onChangeLoading(true);

      let subsidiary = this.props.subsidiary;
      if (this.props.variant === GameFormVariant.Register) {
        let subsidiaryData: SubsidiaryObject = findSubsidiaryByPlz(
          this.props.plz.value
        ) as SubsidiaryObject;
        if (subsidiaryData) {
          subsidiary = subsidiaryData.key;
          // what's this?
          this.handleOnChangeSubsidiary(subsidiary);
        } else {
          // what's this?
          this.handleOnChangeSubsidiaryList(subsidiaryData);
        }
        if (!subsidiary) {
          this.props.onChangeLoading(false);
          return;
        }
      }

      await this.submitGameData(subsidiary);
      MarketingParams.reset();
      this.resetCurrentGameStorage();
    } catch (error) {
      const errorCode = this.parseErrorCode(error);
      if (errorCode) {
        this.props.onChangeError(errorCode);
      } else {
        this.props.onChangeError(-1);
      }
    }
    this.props.onChangeLoading(false);
  };

  private async subsidiary(): Promise<SubsidiaryObject> {
    const response = await fetch(
      `${API.subs.subsidiary}/${this.props.plz.value}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = (await response.json()) as SubsidiaryObject;
    if (!data) {
      throw new Error('Postcode is not found 404');
    }
    return data;
  }

  private submitGameData = async (subsidiary?: string) => {
    const timeout = 8000;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
      const marketing = MarketingParams.get();
      const gameConfig = this.gameConfig;
      const data: any = {
        ...marketing,
        solution: this.state.solution,
        game:
          gameConfig && mapGameToSubmitData(this.props.gameState, gameConfig),
      };
      if (this.props.variant === GameFormVariant.Login) {
        data.subsidiary = this.state.bundesland.substring(0, 2);
        data.formType = REDEMPTION_FORM_TYPE_IDS.LOGIN;
      } else {
        data.subsidiary = subsidiary;
        data.formType = REDEMPTION_FORM_TYPE_IDS.REGISTER;
      }

      const game = this.props.currentGameId;
      let gameName: string = '';

      if (game !== undefined && this.props.gameState) {
        switch (game) {
          case 1:
            gameName = 'Eurojackpot';
            break;
          case 2:
            gameName = 'Glükspirale';
            break;
          case 3:
            gameName = 'Keno';
            break;
          case 4:
            gameName = 'Lotto6aus49';
            break;
        }

        pushGameToDatalayer(
          [
            gameName,
            data.game && data.game.rows && data.game.rows.length,
            this.props.gameState.selectedDrawDays.find(
              (date) => date === 'Mittwoch'
            ),
            this.props.gameState.selectedDrawDays.find(
              (date) => date === 'Samstag'
            ),
            this.props.gameState.selectedDrawDays.length === 2,
            this.props.gameState.selectedWeeksNumber,
            this.props.gameState.selectedWeeklySubscription,
            this.props.variant,
            this.props.gameState.selectedAdditionalGames.find(
              (game) => game === 1
            ) === 1 && true,
            this.props.gameState.selectedAdditionalGames.find(
              (game) => game === 2
            ) === 2 && true,
            this.props.gameState.selectedAdditionalGames.find(
              (game) => game === 3
            ) === 3 && true,
            this.props.gameState.selectedAdditionalGames &&
              this.props.gameState.selectedAdditionalGames.find(
                (game) => game === 4
              ) === 4 &&
              true,
          ],
          DataLayerEvent.PassedTicket
        );
      }

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const response = await fetch(API.subs[this.props.variant], {
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers,
        signal: controller.signal,
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      const responseData = await response.json();
      if (responseData?.url) {
        this.dataInput.value = responseData.data;
        this.gameForm.action = responseData.url;

        // Remove PLZ before sending (LOT23-65)
        const plzNode = document.querySelector('#plz');
        if (plzNode) {
          plzNode.parentNode?.removeChild(plzNode);
        }
        if (this.state.isCaptchaEnabled) {
          // Remove frc captcha solution before send the request to the subsidiary
          const node = document.querySelector('.frc-captcha-solution');
          if (node) {
            node.parentNode?.removeChild(node);
          }
        }

        this.gameForm.submit();
      } else {
        throw new Error('No response data');
      }
    } catch (e) {
      this.props.onChangeError(400);
    } finally {
      this.captchaRefRegister?.current?.resetCaptcha();
      clearTimeout(timeoutId);
    }
  };

  private parseErrorCode(error): number {
    if (error.code === 'ECONNABORTED') {
      return 998;
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string' &&
      error.response.data.message.includes('Captcha is not valid')
    ) {
      if (this.captchaRefLogin.current) {
        this.captchaRefLogin.current.resetCaptcha();
      }
      if (this.captchaRefRegister.current) {
        this.captchaRefRegister.current.resetCaptcha();
      }
      return 999;
    }
    return +(error.message.match(/\d{3}/g) || [''])[0];
  }

  private handleOnChange = (
    key: string,
    value: string,
    isTouched?: boolean
  ) => {
    if (Object.keys(this.props).includes(key)) {
      this.props.onChangeFormControl(key, value, isTouched);
    }
  };

  private handleOnChangeFormVariant: ActionGameFormVariantFunction = (
    variant: GameFormVariant
  ) => {
    this.props.onChangeFormVariant(variant);
  };

  private handleOnChangeSubsidiary: ActionGameFormFunction = (
    subsidiary: string
  ) => {
    this.props.onChangeSubsidiary(subsidiary);
  };

  private handleOnChangeSubsidiaryList: ActionGameFormSubsidiaryListFunction = (
    value: SubsidiaryObject[]
  ) => {
    this.props.onChangeSubsidiaryList(value);
  };

  private doneCallback = (solution) => {
    this.setState({ solution: solution });
  };
}

export default GameForm;
